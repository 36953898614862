import { faStar } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptions } from 'interface/DetailInterface';
import { IReviewListResponse } from 'interface/ReviewInterface';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { IReviews } from 'pages/api/shop/review/[productNo]';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { getCDN340 } from 'util/getCDN';
import maskingName from 'util/maskingName';

import {Swiper, SwiperSlide} from 'swiper/react'
import { Lazy } from 'swiper';
import 'swiper/css';
import 'swiper/css/lazy';
import "swiper/css/pagination";

interface ReviewListProps {
  optionInfo?: IOptions
  reviews?: IReviewListResponse | null,
  onClickReviewImg : ClickReviewImgType,
  disabledOpen?: boolean
}

export interface IReviewMedia {
  reviewId: string
  media: string
  mediaIdx: number
}

export type ClickReviewImgType = (reviewId: string, mediaIdx: number) => void

const ReviewSwiper = ({ optionInfo, reviews, onClickReviewImg, disabledOpen }: ReviewListProps) => {
  
  // 리뷰가 null이면 작성된 리뷰가 없다고 처리
  if (!reviews) return null

  // 리뷰 리스트
  return <ReviewNotNull optionInfo={optionInfo} reviews={reviews} onClickReviewImg={onClickReviewImg} disabledOpen={disabledOpen} />
}

interface ReviewNotNullProps extends ReviewListProps {
  reviews: IReviewListResponse
}

const ReviewNotNull = ({ optionInfo, reviews, onClickReviewImg, disabledOpen }: ReviewNotNullProps) => {
  const router = useRouter()
  
  const [mediaList, setMediaList] = useState<IReviewMedia[] | null>(null);
  const [isReviewDetail, setIsReviewDetail] = useState<boolean>(false);

  useEffect(() => {
      setMediaList(
        reviews.reviews.reduce((prev: IReviewMedia[], cur) => 
        cur.review.medias !== null && cur.review.medias !== undefined
        ? [...prev, ...cur.review.medias.map((media, idx) => ({ reviewId: cur.orderNo, media, mediaIdx: idx }))]
        : prev, [])
      )
  }, [reviews])

  useEffect(() => {
    if (router.isReady) {
        // 리뷰 상세 페이지 설정
        setIsReviewDetail(typeof router.query.review === 'string')
    }
  }, [router.query])

  const getOptionNameByReview = (review: IReviews) => {
    const selectedOptions = (optionInfo as IOptions)?.flatOptions.reduce((prev: string[], cur) => {
      let optionName = ''
      const matchingOption = review.review.optionNos.find(no => {
        if (no === cur.optionNo) {
          optionName = `${cur.label}: ${cur.value}` 
          return true
        }
      })
      return matchingOption ? [...prev, optionName] : prev
    }, [])
  
    return selectedOptions
  }

  if(!reviews) return <></>

  return (
    <section className="pt-2 text-[13px] font-noto py-4">
      <h4 className="a11y-hidden">review</h4>

      <div className="border-b-[4px] border-[#F4F4F4] mb-6"></div>

      <div className='flex mt-2 px-6 justify-between'>
        <span className='text-[1.3em] font-[500]'>🧚‍♀️ 후기요정 pick!</span>
        <button className='text-[1.1em] disabled:opacity-0' disabled={disabledOpen} onClick={()=>router.push({ query : {...router.query, reviews : 'open'}})}>전체보기 &#62;</button>
      </div>

      <div className='px-8 mt-2 mb-3'>
        <span>{reviews.totalCount}명이</span> <FontAwesomeIcon icon={faStar} className='text-[#FFD41F]'/> <span>평균 {reviews.scoreAvg.toFixed(1)}점의 후기를 남겼어요.</span>
      </div>

      {/* 후기 개별 아이템 */}
      <Swiper
          lazy={true}
          modules={[Lazy]}
          slidesPerView={1.1}
          className='padding-applied-swiper'>

          {reviews.reviews.map((review, i)=> {
            const selectedOptions = getOptionNameByReview(review) // 선택한 옵션 구하기
            return (
              <SwiperSlide key={`후기개별아이템-${review.createdAt}-${i}`}>
                <ReviewItem review={review} options={selectedOptions} onClickReviewImg={onClickReviewImg} />
              </SwiperSlide>
            )
          })}
      </Swiper>

    </section>
  )
}

interface ReviewItemProps {
  review: IReviews
  options: string[]
  onClickReviewImg: ClickReviewImgType
}

const ReviewItem = ({ review, options, onClickReviewImg }: ReviewItemProps) => {
  /* -------------------------------- 평가 항목 필터링 ------------------------------- */
  const RATING_ITEM = {
    cs: "💬 고객 응대",
    gift: "🎁 선물용",
    price: "💵 가격",
    quality: "✨ 상품 퀄리티",
    delivery: "🚚️ 배송",
  }

  const ratingItemEntries = Object.entries(RATING_ITEM)
  
  // 사용자가 평가를 남긴 필드 구하기
  const selectedRating = Object.entries(review.review).reduce((prev: string[], curObj) => {
    // RATING_ITEM에 맞는 키 확인
    const matchRating = ratingItemEntries.find(_obj => _obj[0] === curObj[0])
    // RATING_ITEM에 해당하고, 사용자가 평가를 남긴 필드만 필터링
    return (matchRating && curObj[1] !== null) ? [...prev, matchRating[1]] : prev
  }, [])

  
  return (
    <div className='w-[95%] aspect-[1.8/1] bg-[#F5F3F1] rounded-lg'>

      <div className='flex px-3 pt-4'>
        {/* 작성자 */}
        <div className="px-2 mb-3 mr-9 relative">
          <span className="text-[#404040] text-[1.1em] mr-1.5">{maskingName(review.person.nickname || review.person.name)}</span>
          {review.review.babyMonth && <span className={`${'bg-[#FFF9FA] text-[#EB5176] text-[0.76em] px-1 rounded absolute top-1/2 translate-y-[-50%] min-w-10 text-center'}`}>{review.review.babyMonth}개월</span>}
        </div>

        {/* 별점 */}
        <div className="items-center">
          {Array.from({ length: 5 }).map((_, i) => 
            <FontAwesomeIcon key={i} color={(i >= review.review.score) ? '#D9D9D9' : '#FFD41F'} className="w-[1.23em] h-[1.23em]" icon={faStar} />
          )}
        </div>
      </div>

      <div className='flex px-4'>
        {/* 리뷰 썸네일 */}
          {review.review.medias &&(
            <>
            <div className={`${review.review.medias ? 'min-w-[32%]' : 'hidden'}`}>
                  <li key={review.review.medias[0]} className={`${review.review.medias ? 'w-full h-full mr-1.5' : 'hidden'}`}>
                    <button className="relative block w-full aspect-square " disabled={!review.review.medias} onClick={() => onClickReviewImg(review.orderNo, 0)}>
                      <Image fill src={getCDN340(review.review.medias[0])} alt="" className='object-cover rounded-md' />
                    </button>
                  </li>
              </div>
            </>
          )}
  

        <div className={`${review.review.medias ? 'w-[70%] aspect-[2/1]' : 'w-full aspect-[2.8/1]'} relative`}>
          {/* 내용 */}
          <div className='h-1/2 absolute top-4 w-ful'>
            <span className="px-2 w-full line-clamp-3 text-[#2C2C2C]">{review.review.contents}</span>
          </div>

        </div>
      </div>  
      
    </div>
  )
}

export default ReviewSwiper