import axios from "axios"
import { IProductCoupon, IProductDetailInfos, IPriceInfo } from "interface/DetailInterface"
import React, { Dispatch, SetStateAction, useEffect} from "react"
import { priceComma } from "util/priceComma"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { ButtonBase } from "@mui/material"
import {faShareNodes}  from "@fortawesome/pro-light-svg-icons"
import Link from "next/link"
import { useRouter } from "next/router"
import * as airbridge from '../../libs/airbridge';
import { IReviewListResponse } from "interface/ReviewInterface"
import { faStar } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons"
import { useRecoilValue } from "recoil"
import copyClipboardAirbridge from "util/copyClipboardAirbridge"
import WishlistToggleButton from "./WishlistToggleButton"
import { isParentState } from "states/shop/isParent"
import { isSsckEmployeeState } from "states/shop/isSsckEmployee"
import { ManageCodeRegex, removeWordRegex } from "util/regularExpression"
import { HorizontalCardWrapper, HorizontalLeftContents, HorizontalRightContents } from "./HorizontalCard"
import Image from "next/image"
import Tooltip from "@components/snippets/Tooltip"
import CouponItem from "./CouponItem"
import PositionPopup from "@components/snippets/PositionPopup"

interface IDetailProductInfoProps{
    popupOpen :  {
        position: "bottom" | "middle"
        target : string
        open: boolean
    }, 
    setPopupOpen :  Dispatch<SetStateAction<{
        position: "bottom" | "middle"
        target: string
        open: boolean
    }>>,
    productCoupons ?: IProductCoupon[] | [],
    productDetail ?: IProductDetailInfos | null,
    reviews ?: IReviewListResponse | null,
    disabledReviewOpen?: boolean
}

const ProductInfo = ({ 
    popupOpen,
    setPopupOpen, 
    productDetail,
    productCoupons, 
    reviews, 
    disabledReviewOpen
}:IDetailProductInfoProps) =>{
    const router = useRouter()
    const isParent = useRecoilValue(isParentState);
    const isSsckEmployee = useRecoilValue(isSsckEmployeeState);

    const accuDiscountAmt = (
        priceInfo : IPriceInfo
    ) => {
        const adjustedPrice = priceInfo.salePrice - priceInfo.immediateDiscountAmt

        return productCoupons?.length ? priceComma(Math.ceil(productCoupons.reduce((ac, cur) => {
            return cur.discountInfo.discountAmt 
            ? ac + adjustedPrice - cur.discountInfo.discountAmt 
            : ac + (100 - cur.discountInfo.discountRate) / 100 * adjustedPrice
        },0))) : 0
    }

    const hanldeOpenPopup = (
        position : "bottom" | "middle",
        target : "price" | "coupon"
    ) =>{
        setPopupOpen((prev) => {return {
            ...prev, position, target, open : true
        }})
    }

    if (!productDetail) return <ProductInfoSkeleton/>

    return (<>

        <PositionPopup 
            position={popupOpen.position} 
            isOpen={popupOpen.open && !!productCoupons} 
            onClose={() => setPopupOpen(prev => {return {...prev, open : !prev.open}})}
        >   
            {popupOpen.position === "bottom" && 
                popupOpen.target === "coupon" &&
                productCoupons && productCoupons.length > 0 && 
                (productCoupons as IProductCoupon[]).map((coupon, idx) => {
                    return <CouponItem 
                        key={`${coupon.couponNo}-${idx}`}
                        item={coupon} 
                        menuTab="download"
                        extraFunc={() => {}}
                        extraFunc2={() => {}}/>
                }
            )}

            {popupOpen.position === "middle" &&
                popupOpen.target === "price" && (
                <div>
                    <p className="font-semibold">가격안내</p>
                    {/* 할인가격 */}
                    <div>
                        {productDetail.price && (
                        <div className="font-semibold nanum-square-round mt-4">
                            <span className="text-[#EB5176]">
                                {Math.ceil((productDetail.price.immediateDiscountAmt/productDetail.price.salePrice)*100)}%
                            </span> 
                            <span className="line-through text-[#a6a6a6] mx-2">{priceComma(productDetail.price.salePrice)}원</span>
                            <span>{priceComma(productDetail.price.salePrice - productDetail.price.immediateDiscountAmt)}원</span>
                        </div>)}

                        <p className="text-[#979797] text-sm mt-2">원가에서 즉시할인된 가격입니다. 이 가격은 시장 현황 등에 따라 변경될 수 있습니다.</p>
                    </div>
                    {/* 쿠폰할인 */}
                    {productCoupons && productCoupons?.length > 0 && (
                        <div className="nanum-square-round mt-4">
                            <span className="bg-[#EB5176] text-white p-1 text-sm">쿠폰할인</span>
                            <span className="text-[1.2em] font-[700] text-[#EB5176] mx-2">{accuDiscountAmt(productDetail.price)}원</span>

                            <p className="text-[#979797] text-sm mt-2">즉시할인 받은 가격에 상품쿠폰 다운 받아 적용할 시 해당되는 가격을 의미합니다.</p>
                        </div>
                    )}
                </div>
            )}

        </PositionPopup>

        <fieldset className="bg-white font-noto p-4">
            <legend className="invisible text-[1px]">상품정보</legend>
                <div className="flex justify-between"> 
                    <Link
                        shallow={true}
                        scroll={true}
                        href={{
                            pathname: `/shop/brand/[id]`,
                            query: { ...router.query, id: productDetail?.brand?.brandNo } 
                        }}
                        as="/shop/brand/[id]">
                            <div className="flex text-[13px] text-[#9E9E9E]">
                                <div>{productDetail?.brand?.nameKo}</div>
                            </div>
                    </Link>
                        
                    <div>
                        <WishlistToggleButton productNo={productDetail.baseInfo.productNo} />
                        <ButtonBase onClick={async()=>{
                            let title = productDetail.baseInfo.productName;
                            const url = `${location.href}&title=${encodeURI(title)}`;
                            await copyClipboardAirbridge(
                                url, 
                                "market_share",
                                 "상품 상세페이지", 
                                isSsckEmployee ? "쑥찰직원" : isParent ? "쑥찰엄빠" :  "쑥찰가족", 
                                "마켓공유", 
                                title, 
                                productDetail.baseInfo?.imageUrls?.[0], 
                                productDetail.brand?.name, 
                                removeWordRegex(productDetail.baseInfo?.productName)
                            );
                            const product = ManageCodeRegex('goods', productDetail.groupManagementCode) ? 'goodsProduct' : 'commerceProduct'
                            // 에어브릿지 - 공유버튼 클릭
                            airbridge.trackEvent('click_product_share', {
                                'action' : product,
                                'label' : `${productDetail.baseInfo.productNo}`, 
                                'value' : `${productDetail.price.salePrice}`,
                                'semanticAttributes' : {
                                    'products' : [{
                                        'productID' : `${productDetail.baseInfo.productNo}`,
                                        'name' : `${productDetail.baseInfo.productName}`,
                                        'categoryName' : `${productDetail.categories[0].fullCategoryLabel}`,
                                        'brandID' : productDetail.brand ? `${productDetail.brand?.brandNo}` : null, 
                                        'brandName' : productDetail.brand ? `${productDetail.brand?.nameKo}` : null,
                                        'price' : `${productDetail.price.salePrice}`
                                    }]
                                }
                            })
                        }}>
                            <FontAwesomeIcon icon={faShareNodes} className="ml-1  w-[1.5em] h-[1.5em]"/>
                        </ButtonBase>
                    </div>
                </div>
    
                <div className="mt-1 font-bold text-[1.2em]">{removeWordRegex(productDetail?.baseInfo.productName)}</div>
                    
                {/* 평균 별점 */}
                {reviews && 
                (<div className="font-14">
                    <FontAwesomeIcon icon={faStar} className="text-[#F6C944] w-4 h-4" />
                    <span className="mx-[6px]">{reviews?.scoreAvg.toFixed(1)}</span>
                    <button className="underline" disabled={disabledReviewOpen} onClick={()=>{router.push({ query: { ...router.query, reviews: 'open' }})}}>{reviews?.totalCount}개의 리뷰</button>
                </div>)}
                    
                <HorizontalCardWrapper boundaries={true}>
                    <HorizontalLeftContents>
                        {productDetail.price && (<div>
                            <div className="font-semibold nanum-square-round">
                                {Math.ceil((productDetail.price.immediateDiscountAmt/productDetail.price.salePrice)*100) !== 0
                                && (<>
                                    <span className="text-[#EB5176]">{Math.ceil((productDetail.price.immediateDiscountAmt/productDetail.price.salePrice)*100)}%</span> 
                                    <span className="line-through text-[#a6a6a6] mx-2">{priceComma(productDetail.price.salePrice)}원</span>
                                </>)}
                                <span>{priceComma(productDetail.price.salePrice - productDetail.price.immediateDiscountAmt)}원</span>
                                <ButtonBase
                                onClick={() =>hanldeOpenPopup("middle", "price")}>
                                    <FontAwesomeIcon icon={faCircleExclamation} className="w-4 h-4 mx-2 text-[#979797]"/>
                                </ButtonBase>
                            </div>
                        </div>)}

                        {productCoupons && productCoupons.length > 0 && (
                            <div className="nanum-square-round mt-1">
                                <span className="bg-[#EB5176] text-white p-1 text-sm">쿠폰할인</span>
                                <span className="text-[1.2em] font-[700] text-[#EB5176] mx-2">
                                    {accuDiscountAmt(productDetail.price)}원
                                </span>
                            </div>
                        )}
                    </HorizontalLeftContents>

                    <HorizontalRightContents classStyle="text-right text-[#EB5176] relative">
                        {productCoupons && productCoupons.length > 0 && (
                            <>
                                <Tooltip
                                tipMsg="추가할인혜택♥"
                                position="absolute right-2 -top-6 opacity-[0.96] whitespace-nowrap"
                                bgColor="#FDF2F3"
                                textColor="#EB5176"
                                tailDirection="bottom-tail-right-light-pink"/>

                                <ButtonBase onClick={() =>hanldeOpenPopup("bottom", "coupon")} className="w-[100px] h-11 mt-3 rounded-full" style={{ border : "0.5px solid #EB5176"}}>
                                    <Image src="/assets/download3.png" alt="다운로드 아이콘" width={18} height={18}/>
                                    <span className="text-[0.85em]">상품쿠폰받기</span>
                                </ButtonBase>
                            </>
                        )}
                    </HorizontalRightContents>
                </HorizontalCardWrapper>
        </fieldset>
    </>)
}

const ProductInfoSkeleton = () => {
    return (
        <div className="h-[200px] p-4 bg-white">
            <div className="bg-[#f4f4f4] h-4 w-14"></div>
            <div className="bg-[#f4f4f4] h-6 w-[80%] mt-1"></div>

            <div className="bg-[#f4f4f4] h-9 w-[80%] mt-3"></div>
        </div>
    )
}

export default ProductInfo